import React, { FC } from 'react';

import s from './../FurnituresSlider.scss';
import Arrow from '../../../../assets/svg/arrow.svg';
import { useAppDispatchContext, useAppStateContext } from '../../../../context/appContext';

interface IArrowProps {
  handleClick(): void;

  isActive: boolean;
}

export const ArrowLeft: FC<IArrowProps> = ({ handleClick, isActive }) => {
  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <div className={s(s.slider__arrowPrev, { active: isActive })} onMouseEnter={() => onCursor('action')}
         onMouseLeave={() => onCursor()}
         onClick={handleClick}>
      <Arrow className={s.slider__arrowPrevSvg} />
    </div>
  );
};

export const ArrowRight: FC<IArrowProps> = ({ handleClick, isActive }) => {
  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  return (
    <div className={s(s.slider__arrowNext, { active: isActive })} onMouseEnter={() => onCursor('action')}
         onMouseLeave={() => onCursor()}
         onClick={handleClick}>
      <Arrow className={s.slider__arrowNextSvg} />
    </div>
  );
};  
