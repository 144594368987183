import React, { useEffect, useRef, useState } from 'react';
import { useIntl } from 'gatsby-plugin-intl';

import Img from 'gatsby-image';
import { useAppDispatchContext, useAppStateContext } from '../../../context/appContext';
import { Container } from '../../container/Container';
import Close from 'assets/svg/close.svg';
import { ArrowLeft, ArrowRight } from './Arrows/Arrows';
import gsap from 'gsap';

import s from './FurnituresSlider.scss';
import { SliderLabel } from '../../slider-label/SliderLabel';

export const FurnituresSlider = ({
                                   photo,
                                   sliderData,
                                   slides,
                                   title,
                                   isOpen,
                                   onClose,
                                   index,
                                   forwardedFooter,
                                   forwardedDimensions,
                                   forwardedName,
                                   forwardedSubline,
                                   forwardedImageWrap,
                                   forwardedModal,
                                   setSliderIndex,
                                 }) => {
  const intl = useIntl();
  const dispatch = useAppDispatchContext();
  const [additionalSources, setAdditionalSources] = useState(false);
  const [isArrowLeftActive, setIsArrowLeftActive] = useState(true);
  const [isArrowRightActive, setIsArrowRightActive] = useState(true);

  let isProcessing = false;

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const decreaseOpacityifSoon = (index: number) => {
    if (sliderData[index].soon) {
      gsap.set(forwardedImageWrap.current?.children[index], { opacity: 0.3 });
    } else {
      gsap.set(forwardedImageWrap.current?.children[index], { opacity: 1 });
    }
  };

  const handlePreviousClick = () => {
    if (!isProcessing) {
      const DURATION = .3;

      isProcessing = true;
      const tl = gsap.timeline();

      if (forwardedImageWrap.current) {
        if (index !== 0) {
          tl.to(forwardedImageWrap.current, { x: '+=100vw', duration: DURATION, ease: 'expo' });

          decreaseOpacityifSoon(index - 1);

          setTimeout(() => {
            isProcessing = false;
            setSliderIndex(index - 1);
          }, DURATION * 1000);
        }
      }
    }
  };

  const handleNextClick = () => {
    if(isArrowRightActive) {
      if (!isProcessing) {
        const DURATION = .3;

        isProcessing = true;
        const tl = gsap.timeline();

        if (forwardedImageWrap.current) {
          if (index !== forwardedImageWrap.current.children.length - 1) {
            tl.to(forwardedImageWrap.current, { x: '-=100vw', duration: DURATION });

            decreaseOpacityifSoon(index + 1);


            setTimeout(() => {
              isProcessing = false;
              setSliderIndex(index + 1);
            }, DURATION * 1000);
          }
        }
      }
    }
  };

  const prepareCover = (i) => {
    const sourcesPart1 = sliderData[i].gallery[0].image.childImageSharp.fluid;

    let sourcesPart2;

    if (sliderData[i].gallery.length > 1 && sliderData[i].gallery[1].image) {
      sourcesPart2 = sliderData[i].gallery[1].image.childImageSharp.fluid;
    } else {
      sourcesPart2 = sourcesPart1;
    }

    return [sourcesPart1, sourcesPart2];
  };

  useEffect(() => {
    if (forwardedImageWrap.current) {
      gsap.set(forwardedImageWrap.current, { x: `-${index * 100}vw` });
    }
    if (index === 0) {
      setIsArrowLeftActive(false);
    } else {
      setIsArrowLeftActive(true);
    }

    if (index === forwardedImageWrap.current.children.length - 1) {
      setIsArrowRightActive(false);
    } else {
      setIsArrowRightActive(true);
    }

  }, [index]);

  return (
    <div className={s(s.slider)} ref={forwardedModal}>
      <div className={s.slider__arrowContainer}>
        <div className={s.slider__arrowSvgl}>

          {isArrowLeftActive
            ? <ArrowLeft handleClick={handlePreviousClick} isActive={isArrowLeftActive} />
            : ''
          }
        </div>
        <div className={s.slider__image} ref={forwardedImageWrap}
             onMouseOver={() => {
               if (prepareCover(index)[1]) {
                 additionalSources ?
                   onCursor('furnitureCoverPressed') :
                   onCursor('furnitureCoverHover');
               } else {
                 onCursor('whiteDefault');
               }
             }}
             onMouseLeave={() => onCursor()}
             onMouseDown={() => {
               setAdditionalSources(true);
               prepareCover(index)[1] ?
                 onCursor('furnitureCoverPressed') :
                 onCursor('whiteDefault');
             }}
             onMouseUp={() => {
               setAdditionalSources(false);
               prepareCover(index)[1] ?
                 onCursor('furnitureCoverHover') :
                 onCursor('whiteDefault');
             }}>

          {sliderData.map((element, i) => {

            return (
              <div className={s.slider__imageWrapper} key={i}>
                <Img
                  className={s(s.cover__defaultPhoto, { isActive: !additionalSources })}
                  fluid={prepareCover(i)[0]}
                  imgStyle={{ objectPosition: '50% 60%' }}
                  style={{ position: 'static', width: '100vw' }}
                />

                <Img
                  className={s(s.slider__additionalPhoto, s.slider__additionalPhotoUp, { isActive: additionalSources })}
                  fluid={prepareCover(i)[1]}
                  imgStyle={{ objectPosition: '50% 60%' }}
                  style={{ position: 'static', width: '100vw' }}
                />
              </div>
            );
          })}

        </div>
        <div className={s.slider__arrowSvgr}>
          {
            isArrowRightActive
              ? <ArrowRight handleClick={handleNextClick} isActive={isArrowRightActive} />
              : ''
          }
        </div>
      </div>
      <div className={s.slider__footer} ref={forwardedFooter}>
        <Container>
          <div className={s.slider__footerRow}>
            <div className={s.slider__header}>
              <h3 className={s.slider__name} ref={forwardedName}>{slides[index].name} {sliderData[index].soon ?
                <SliderLabel content={'Soon'} /> : ''}</h3>
              <p className={s.slider__subline} ref={forwardedSubline}>{title}</p>
            </div>
            <div className={s.slider__dimensions} ref={forwardedDimensions}>
              <span
                className={s.slider__dimension}><span>{intl.formatMessage({ id: 'width' })} </span>{slides[index].width}</span>
              <span
                className={s.slider__dimension}><span>{intl.formatMessage({ id: 'height' })}</span>{slides[index].height}</span>
              <span
                className={s.slider__dimension}><span>{intl.formatMessage({ id: 'depth' })}</span>{slides[index].depth}</span>
            </div>
            <div className={s.slider__close}
                 onMouseOver={() => onCursor('action')}
                 onMouseLeave={() => onCursor()}
                 onClick={() => onClose(false)}
            >
              <Close className={s.slider__closeSvg} />
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
