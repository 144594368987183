import React, { useEffect, useMemo, useRef, useState } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-intl';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import CollectionsSlider from '../components/magnify-slider/collections-slider/CollectionsSlider';
import { MagnifySlider } from '../components/magnify-slider/MagnifySlider';

import { FormWrapper } from '../components/formWrapper/FormWrapper';
import { Cover } from 'components/cover/Cover';
import { BlockText } from 'components/block-text/BlockText';
import { Container } from 'components/container/Container';
import { Form } from 'components/form/Form';
import { gsap, Power2, TimelineLite } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { SplitText } from '../utils/SplitText';

import s from './collectionTemplate.scss';

interface Props {
  data: {
    markdownRemark: any
  }
}

export default ({ data }: Props) => {
  const intl = useIntl();
  const [animationToLoad, setAnimationToLoad] = useState(true);

  const { file } = data; // data.markdownRemark holds your post data
  const { childMarkdownRemark, fields } = file;
  const { frontmatter } = childMarkdownRemark;
  const { page } = fields;

  const prepareSlidesData = () => {
    const sliedesImages = frontmatter.collection;
    const slidesData = page.collection;

    let preparedFurniture = [];

    sliedesImages.map((silde) => {
      const furnitureData = slidesData.find(data => data.name === silde.name);

      preparedFurniture.push({
        ...furnitureData,
        ...silde,
      });
    });

    return preparedFurniture;
  };

  const prepareCover = () => {
    const sourcesPart1 = [
      frontmatter.coversMobile[0].childImageSharp.fluid,
      {
        ...frontmatter.covers[0].childImageSharp.fluid,
        media: `(min-width: 768px)`,
      },
    ];

    let sourcesPart2;

    if (frontmatter.covers.length > 1) {
      sourcesPart2 = [
        frontmatter.coversMobile[1].childImageSharp.fluid,
        {
          ...frontmatter.covers[1].childImageSharp.fluid,
          media: `(min-width: 768px)`,
        },
      ];
    }

    return [sourcesPart1, sourcesPart2];
  };

  const markdownTexts = useRef();

  const fadeIn = markdownTexts => {
    const splitChilds = new SplitText(markdownTexts.current, { type: 'lines', linesClass: 'lineChild' });
    const splitChilds2 = new SplitText(markdownTexts.current, { type: 'lines', linesClass: 'lineParent' });
    const lines = splitChilds.lines;

    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: markdownTexts.current,
        start: 'top 70%',
      },
    });

    tl.fromTo(lines, {
        autoAlpha: 0, y: 50,
        rotate: 3,
      },
      {
        duration: 2,
        y: 0,
        autoAlpha: 1,
        rotate: 0,
        stagger: .2,
        ease: 'slow',
      });
  };

  useEffect(() => {
    fadeIn(markdownTexts);
  }, []);

  return useMemo(() => {
    return (
      <IntlContextConsumer>
        {({ language: lang }) => (
          <>
            <Helmet title="About" />
            <Cover photo={prepareCover()} />
            <Container>
              <BlockText heading={page.title} description={intl.formatMessage({ id: page.subline })}>
                <div className={s.collectionTemplate} ref={markdownTexts}>
                  {intl.formatMessage({ id: page.markdownText1 })}<br /><br />
                  {intl.formatMessage({ id: page.markdownText2 })}<br /><br />
                  {intl.formatMessage({ id: page.markdownText3 })}<br /><br />
                  {intl.formatMessage({ id: page.markdownText4 })}<br /><br />
                </div>
              </BlockText>

              <MagnifySlider
                frontmatter={frontmatter}
                page={page}
                sliderData={prepareSlidesData()}
              />

              <FormWrapper child={
                <Form
                  topText={`${intl.formatMessage({ id: 'collectionTemplateFormTopTextPart1' })} ${page.title} ${intl.formatMessage({ id: 'collectionTemplateFormTopTextPart2' })}`}
                  secondText={intl.formatMessage({ id: 'collectionTemplateFormSecondText' })}
                  collectionName={page.title}
                  collectionQuestion
                  lang={lang}
                />
              }
              />
            </Container>
            <CollectionsSlider />
          </>
        )}
      </IntlContextConsumer>
    );
  }, []);
}
;

export const pageQuery = graphql`
  query($relativePath: String!) {
    file(relativePath: {eq: $relativePath}) {
      childMarkdownRemark {
        frontmatter {
          collection {
            name
            gallery {
              image {
                childImageSharp {
                  fluid(maxWidth: 1440, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
            image {
              childImageSharp {
                fixed(width: 350, quality: 90) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
          covers {
            childImageSharp {
              fluid(maxWidth: 1440, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          coversMobile {
            childImageSharp {
              fluid(maxWidth: 375) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          featured {
            childImageSharp {
              fixed(width: 661) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
      fields {
        page {
          versions {
            lang
          }
          heroText
          id
          lang
          pageId
          markdownText1
          markdownText2
          markdownText3
          markdownText4
          miniatures {
            cover
            height
            top
            left
            width
          }
          path
          title
          type
          subline
          featuredMobile
          collection {
            depth
            height
            name
            soon
            width
          }
        }
      }
    }
  }
`;
