import React, { useEffect, useMemo, useRef, useState } from 'react';
import Img from 'gatsby-image';
import { TimelineLite, Power3, gsap } from 'gsap';
import { Draggable } from '../../utils/Draggable';
import { InertiaPlugin } from '../../utils/InertiaPlugin';
import { SliderLabel } from '../slider-label/SliderLabel';
import Arrow from '../../assets/svg/arrow.svg';
import { useAppDispatchContext } from '../../context/appContext';

import s from './CollectionSlider.scss';

export const CollectionSlider = ({ slides, open, setSliderIndex, forwardedThumb, sliderData }) => {

  const dispatch = useAppDispatchContext();

  const [isArrowLeftActive, setArrowLeftActive] = useState(false);
  const [isArrowRightActive, setArrowRightActive] = useState(true);
  const [xTranslate, setXTranslate] = useState<number>(0);

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const onDragStart = () => {
    setArrowLeftActive(false);
    setArrowRightActive(false);
  };

  const TRANSLATE_STEP = 381;
  const slidesNumber = slides.length;
  const TOTAL_TRANSLATE_VALUE = slidesNumber * TRANSLATE_STEP;
  const MIN_X = -1 * (TOTAL_TRANSLATE_VALUE - TRANSLATE_STEP);
  const MAX_X = 0;
  const DURATION = .5;

  useEffect(() => {
    gsap.set(wrapperRef.current, { width: TOTAL_TRANSLATE_VALUE });
  });


  const onThrowComplete = () => {
    const transform = wrapperRef.current.style.transform;
    const fromEnd = 25;
    const length = transform.length - fromEnd;

    const xTranslateValue = parseInt(transform.substr(12, length));

    setXTranslate(xTranslateValue);

    if (xTranslateValue === MIN_X) {
      setArrowRightActive(false);
    } else {
      setArrowRightActive(true);
    }

    if (xTranslateValue === 0) {
      setArrowLeftActive(false);
    } else {
      setArrowLeftActive(true);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(Draggable, InertiaPlugin);

    Draggable.create(wrapperRef.current, {
      type: 'x',
      bounds: {
        minX: MIN_X,
        maxX: 0,
      },
      inertia: true,
      onThrowComplete,
      onDragStart,
    });
  }, []);


  const wrapperRef = useRef(null);
  const tl = new TimelineLite();

  const txNext = () => {
    if (isArrowRightActive) {
      setArrowRightActive(false);
      setArrowLeftActive(false);

      const nextTranslateValue = xTranslate - TRANSLATE_STEP;
      let value;
      if (nextTranslateValue < MIN_X) {
        value = (TOTAL_TRANSLATE_VALUE - TRANSLATE_STEP) + xTranslate;
      } else {
        value = TRANSLATE_STEP;
      }

      tl.to(wrapperRef.current, { x: `-=${value}px`, ease: Power3.easeOut, duration: DURATION });

      setTimeout(() => {
        if (nextTranslateValue <= MIN_X) {
          setArrowRightActive(false);
          setArrowLeftActive(true);
        } else {
          setArrowRightActive(true);
          setArrowLeftActive(true);
        }
        setXTranslate(xValue => xValue - value);
      }, DURATION * 1000);

    }
  };

  const txBack = () => {
    if (isArrowLeftActive) {
      setArrowLeftActive(false);
      setArrowRightActive(false);

      const nextTranslateValue = xTranslate + TRANSLATE_STEP;
      let value;
      if (nextTranslateValue > MAX_X) {
        value = MAX_X - xTranslate;
      } else {
        value = TRANSLATE_STEP;
      }
      tl.to(wrapperRef.current, { x: `+=${value}px`, ease: Power3.easeOut, duration: DURATION });

      setTimeout(() => {
        if (nextTranslateValue >= MAX_X) {
          setArrowLeftActive(false);
          setArrowRightActive(true);
        } else {
          setArrowLeftActive(true);
          setArrowRightActive(true);
        }
        setXTranslate(xValue => xValue + value);
      }, DURATION * 1000);
    }
  };

  const openSlide = (i) => {
    open(true);
    setSliderIndex(i);
  };

  return (
    <div className={s.slider} id={'furnitureSlider'}>
      <div
        className={s.slider__wrapper}
        ref={wrapperRef}
        onMouseEnter={() => onCursor('furnitureSliderHover')}
        onMouseLeave={() => onCursor()}
      >
        {slides.map((slide, i) => {
          return (
            <div className={s(s.slider__slide, { commingSoon: slide.soon })}
                 key={slide.name}
                 onClick={() => openSlide(i)}
                 ref={forwardedThumb}
            >
              <Img fixed={slide.image.childImageSharp.fixed} />
              {sliderData[i].soon ? <SliderLabel content={'Soon'} style={{ top: 5, left: 5 }} /> : ''}
            </div>
          );
        })}
      </div>
      <div onClick={() => txBack()} onMouseOver={() => onCursor('action')} onMouseLeave={() => onCursor()}>
        <Arrow className={s(s.slider__arrowSvgl, { active: isArrowLeftActive })} />
      </div>
      <div onClick={() => txNext()} onMouseOver={() => onCursor('action')} onMouseLeave={() => onCursor()}>
        <Arrow className={s(s.slider__arrowSvgr, { active: isArrowRightActive })} />
      </div>
    </div>
  );
};
