import { gsap, Power3, Power2, Power4 } from 'gsap';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CollectionSlider } from '../collection-slider/CollectionSlider';
import { FurnituresSlider } from './furnitures-slider/FurnituresSlider';

export const MagnifySlider = ({ page, sliderData }) => {


  const [isSliderOpen, setSliderOpen] = useState(false);
  const [sliderIndex, setSliderIndex] = useState(0);

  const dimensions = useRef([]);
  const name = useRef([]);
  const subline = useRef([]);
  const imageWrap = useRef([]);
  const modal = useRef([]);
  const thumb = useRef([]);
  const footer = useRef([]);

  useEffect(() => {
    const tl = gsap.timeline();

    tl.set(modal.current, { autoAlpha: 0 });
  }, []);

  function getPositionAtCenter(element) {
    const { top, left, width, height } = element.getBoundingClientRect();
    return {
      x: left + width / 2,
      y: top + height / 2,
    };
  }

  const getDistanceBetweenElements = (elementA, elementB) => {
    const aPosition = getPositionAtCenter(elementA);
    const bPosition = getPositionAtCenter(elementB);

    return Math.hypot(aPosition.x - bPosition.x, aPosition.y - bPosition.y);

  };

  const toggleSliderVisibility = useCallback((value: React.SetStateAction<string>) => {
    setSliderOpen(value);

    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      if (value) {
        const distance = getDistanceBetweenElements(document.getElementById('furnitureSlider'), document.getElementById('coverSection'));

        document.body.style.position = 'fixed';
        document.body.style.top = `-${distance}px`;
      } else {
        const scrollY = document.body.style.top;
        document.body.style.position = '';
        document.body.style.top = '';
        window.scrollTo(0, parseInt(scrollY || '0') * -1);
      }
    }


    const tl2 = gsap.timeline();
    if (value) {

      tl2.set(footer.current, { autoAlpha: 0, scaleY: 0, transformOrigin: 'bottom' });
      tl2.set(dimensions.current, { autoAlpha: 0 });
      tl2.set(imageWrap.current, { autoAlpha: 0 });
      tl2.set(subline.current, { autoAlpha: 0, yPercent: -5 });
      tl2.set(name.current, { autoAlpha: 0, yPercent: 10 });

      tl2.addLabel('open');
      tl2.to(modal.current, {
        backgroundColor: '#bebebe',
        autoAlpha: 1,
        duration: .25,
        ease: Power3.easeInOut,
      }, 'open');
      tl2.to(footer.current, { scaleY: 1, autoAlpha: 1, duration: 1, ease: Power3.easeOut }, 'open');
      tl2.to(imageWrap.current, { duration: .5, autoAlpha: 1, ease: Power3.easeOut }, 'open+=.8');
      tl2.to(name.current, { duration: .5, autoAlpha: 1, stagger: .1, yPercent: 0, ease: Power3.easeOut }, 'open+=.8');
      tl2.to(subline.current, { duration: .5, autoAlpha: 1, yPercent: 0, ease: Power3.easeOut }, 'open+=.8');
      tl2.to(dimensions.current, { duration: .5, autoAlpha: 1, yPercent: 0, ease: Power3.easeOut }, 'open+=.8');
    } else {
      tl2.set(footer.current, { autoAlpha: 0, scaleY: 0, transformOrigin: 'bottom' });
      tl2.set(dimensions.current, { autoAlpha: 0 });
      tl2.set(imageWrap.current, { autoAlpha: 0 });
      tl2.set(subline.current, { autoAlpha: 0 });
      tl2.set(name.current, { autoAlpha: 0 });
      tl2.set(modal.current, { autoAlpha: 0 });
    }
  }, [setSliderOpen]);

  const sliderSlideIndex = i => {
    setSliderIndex(i);
  };

  return useMemo(() => {
    return (
      <>
        <CollectionSlider slides={sliderData}
                          open={toggleSliderVisibility}
                          setSliderIndex={sliderSlideIndex}
                          forwardedThumb={thumb}
                          sliderData={sliderData}
        />
        <FurnituresSlider
          sliderData={sliderData}
          slides={sliderData}
          title={page.title}
          isOpen={isSliderOpen}
          onClose={toggleSliderVisibility}
          index={sliderIndex}
          forwardedDimensions={dimensions}
          forwardedName={name}
          forwardedFooter={footer}
          forwardedSubline={subline}
          forwardedImageWrap={imageWrap}
          forwardedModal={modal}
          setSliderIndex={setSliderIndex}
        />
      </>
    );
  }, [isSliderOpen, sliderIndex]);
};
