import React, { FC } from 'react';

import s from './SliderLabel.scss';

interface ISliderLabelProps {
  content: string;
  style: object;
}

export const SliderLabel: FC<ISliderLabelProps> = ({ content , style}) => {

  return (
    <span className={s.slider__nameSoon} style={style}>{content}</span>
  );
};
