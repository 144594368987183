import React, { useEffect, useMemo, useRef, useState } from 'react';
import Img from 'gatsby-image';
import { useAppDispatchContext } from '../../context/appContext';
import { gsap } from 'gsap';

import s from './Cover.scss';

export const Cover = ({ photo }) => {

  const dispatch = useAppDispatchContext();

  const onCursor = cursorType => {
    cursorType = cursorType ? cursorType : false;
    dispatch({ type: 'CURSOR_TYPE', cursorType: cursorType });
  };

  const [additionalSources, setAdditionalSources] = useState(false);
  const lineIndicator = useRef(null);

  const animateLineIndicator = (timeline) => {
    timeline.to(lineIndicator.current, {
      width: '100%',
      ease: 'slow',
      duration: .8,
      delay: .1,
    });
  };

  const animateLineIndicatorBack = (timeline) => {
    timeline.to(lineIndicator.current, {
      width: '30%',
      ease: 'power2',
      duration: 1.4,
    });
  };

  useEffect(() => {
    const tl = gsap.timeline();
    if (additionalSources && photo[1]) {
      animateLineIndicator(tl);
    } else {
      animateLineIndicatorBack(tl);
    }

    return () => {
      tl.kill();
    };
  }, [additionalSources]);

  return useMemo(() => {
    return (
      <div className={s.cover} id={'coverSection'}>
      <span
        className={s.cover__placeholder}
        onMouseOver={() => {
          if (photo[1]) {
            additionalSources ?
              onCursor('collectionCoverPressed') :
              onCursor('collectionCoverHover');
          } else {
            onCursor('whiteDefault');
          }
        }}
        onMouseLeave={() => onCursor()}
        onMouseDown={() => {
          if (photo[1]) {
            setAdditionalSources(true);
            onCursor('collectionCoverPressed');
          } else {
            onCursor('whiteDefault');
          }
        }}
        onMouseUp={() => {
          if (photo[1]) {
            setAdditionalSources(false);
            onCursor('collectionCoverHover');
          } else {
            onCursor('whiteDefault');
          }
        }}>
        <Img
          className={s(s.cover__defaultPhoto, { isActive: !additionalSources })}
          fluid={photo[0]}
        />
        {photo[1] && <Img
          className={s(s.cover__additionalPhoto, s.cover__additionalPhotoUp, { isActive: additionalSources })}
          fluid={photo[1]}
          style={{ position: 'absolute' }}
        />}
      </span>
        <div className={s.collectionLine}>
          <div className={s.collectionLineIndicator} ref={lineIndicator} />
        </div>
      </div>
    );
  }, [onCursor]);
};
